.Button {
  display: inline-block;
  box-sizing: border-box;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: 150ms ease;
  transition-property: color, background-color, box-shadow, border-color;
  height: min-content;
  padding: var(--spacing-xs);
  border-radius: var(--radii-l);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  transition:
    filter 400ms,
    color 200ms;

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    transition:
      filter 250ms,
      color 200ms;
    filter: saturate(1.4) brightness(115%);
  }

  &--color {
    &-primary {
      color: var(--color-text-white);
      background-color: var(--color-background-primary);
      border: var(--border-width-m) solid var(--color-border-primary);
      &--disabled {
        background-color: var(--color-background-primary-light);
        border: var(--border-m-primary-light);
      }
    }
  }
}
