.ProgressBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  counter-reset: step;

  &__Step {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--color-text-main);
    &:before {
      margin-inline: auto;
      content: counter(step);
      counter-increment: step;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: var(--color-background-primary);
      margin: 1rem auto 0;
      border: var(--border-l-white);
    }

    &--active ~ &::before {
      background-color: var(--color-background-primary-extra-light);
    }

    &:not(:last-child):after {
      content: "";
      position: relative;
      top: 2.75rem;
      left: 50%;
      height: 2px;
      background-color: #e0e0e0;
      order: -1;
    }
  }
}
