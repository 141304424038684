.Heading {
  font-family: var(--font-heading);
  font-weight: 700;
  color: var(--color-heading-main);
  &--size {
    &-h1 {
      font-size: var(--font-size-xxxxl);
    }
    &-h2 {
      font-size: var(--font-size-xxxl);
    }
    &-h3 {
      font-size: var(--font-size-xxl);
    }
    &-h4 {
      font-size: var(--font-size-xl);
    }
    &-h5 {
      font-size: var(--font-size-l);
    }
  }
  &--variant {
    &-primary {
      color: var(--color-heading-primary);
    }
  }
  &--align {
    &-start {
      text-align: start;
    }
    &-center {
      text-align: center;
    }
    &-end {
      text-align: end;
    }
  }
}
