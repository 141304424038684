.Link {
  display: inline-block;
  box-sizing: border-box;
  appearance: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: 150ms ease;
  transition-property: color, background-color, box-shadow, border-color;
  height: min-content;
  padding: var(--spacing-xs);
  border-radius: var(--radii-l);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  transition:
    filter 400ms,
    color 200ms;

  &:hover:not(.Link--color--disabled),
  &:focus:not(.Link--color--disabled),
  &:focus-within:not(.Link--color--disabled),
  &:active:not(.Link--color--disabled) {
    transition:
      filter 250ms,
      color 200ms;
    filter: saturate(1.4) brightness(115%);
  }

  &--color--disabled {
    cursor: default;
  }

  &--color {
    &-primary {
      color: var(--color-text-primary);
      &.Link--color--disabled {
        color: var(--color-text-primary-light);
      }
    }
  }
}
