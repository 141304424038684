.TypePicker {
  &__Root {
    display: flex;
    gap: var(--spacing-xs);
    &--direction {
      &-row {
        flex-direction: row;
      }
      &-column {
        flex-direction: column;
      }
    }
  }
  &__Option {
    &__Field {
      display: none;
    }

    &__Field:checked + &__Clickable {
      background-color: var(--color-background-primary-light);
    }

    &__Clickable {
      cursor: pointer;
      color: var(--color-text-main);
      border: var(--border-m-primary-light);
      padding: var(--spacing-xs);
      border-radius: var(--radii-m);
      text-align: center;
    }
  }
}
