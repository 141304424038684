.Text {
  font-family: var(--font-text);
  &--color {
    &-primary {
      color: var(--color-text-primary);
    }
    &-secondary {
      color: var(--color-text-secondary);
    }
  }
  &--size {
    &-s {
      font-size: var(--font-size-s);
    }
    &-m {
      font-size: var(--font-size-m);
    }
  }
  &--variant {
    &-bold {
      font-weight: var(--font-weight-bold);
    }
    &-medium {
      font-weight: var(--font-weight-medium);
    }
    &-regular {
      font-weight: var(--font-weight-regular);
    }
  }
  &--align {
    &-start {
      text-align: start;
    }
    &-center {
      text-align: center;
    }
    &-end {
      text-align: end;
    }
  }
}
