.Box {
  &--padding {
    &-xxxs {
      padding: var(--spacing-xxxs);
    }
    &-xxs {
      padding: var(--spacing-xxs);
    }
    &-xs {
      padding: var(--spacing-xs);
    }
    &-s {
      padding: var(--spacing-s);
    }
    &-m {
      padding: var(--spacing-m);
    }
    &-l {
      padding: var(--spacing-l);
    }
    &-xl {
      padding: var(--spacing-xl);
    }
    &-xxl {
      padding: var(--spacing-xxl);
    }
    &-xxxl {
      padding: var(--spacing-xxxl);
    }
    &-xxxxl {
      padding: var(--spacing-xxxxl);
    }
    &-inline-end {
      &-xxxs {
        padding-inline-end: var(--spacing-xxxs);
      }
      &-xxs {
        padding-inline-end: var(--spacing-xxs);
      }
      &-xs {
        padding-inline-end: var(--spacing-xs);
      }
      &-s {
        padding-inline-end: var(--spacing-s);
      }
      &-m {
        padding-inline-end: var(--spacing-m);
      }
      &-l {
        padding-inline-end: var(--spacing-l);
      }
      &-xl {
        padding-inline-end: var(--spacing-xl);
      }
      &-xxl {
        padding-inline-end: var(--spacing-xxl);
      }
      &-xxxl {
        padding-inline-end: var(--spacing-xxxl);
      }
      &-xxxxl {
        padding-inline-end: var(--spacing-xxxxl);
      }
    }
    &-inline-start {
      &-xxxs {
        padding-inline-start: var(--spacing-xxxs);
      }
      &-xxs {
        padding-inline-start: var(--spacing-xxs);
      }
      &-xs {
        padding-inline-start: var(--spacing-xs);
      }
      &-s {
        padding-inline-start: var(--spacing-s);
      }
      &-m {
        padding-inline-start: var(--spacing-m);
      }
      &-l {
        padding-inline-start: var(--spacing-l);
      }
      &-xl {
        padding-inline-start: var(--spacing-xl);
      }
      &-xxl {
        padding-inline-start: var(--spacing-xxl);
      }
      &-xxxl {
        padding-inline-start: var(--spacing-xxxl);
      }
      &-xxxxl {
        padding-inline-start: var(--spacing-xxxxl);
      }
    }
    &-block-end {
      &-xxxs {
        padding-block-end: var(--spacing-xxxs);
      }
      &-xxs {
        padding-block-end: var(--spacing-xxs);
      }
      &-xs {
        padding-block-end: var(--spacing-xs);
      }
      &-s {
        padding-block-end: var(--spacing-s);
      }
      &-m {
        padding-block-end: var(--spacing-m);
      }
      &-l {
        padding-block-end: var(--spacing-l);
      }
      &-xl {
        padding-block-end: var(--spacing-xl);
      }
      &-xxl {
        padding-block-end: var(--spacing-xxl);
      }
      &-xxxl {
        padding-block-end: var(--spacing-xxxl);
      }
      &-xxxxl {
        padding-block-end: var(--spacing-xxxxl);
      }
    }
    &-block-start {
      &-xxxs {
        padding-block-start: var(--spacing-xxxs);
      }
      &-xxs {
        padding-block-start: var(--spacing-xxs);
      }
      &-xs {
        padding-block-start: var(--spacing-xs);
      }
      &-s {
        padding-block-start: var(--spacing-s);
      }
      &-m {
        padding-block-start: var(--spacing-m);
      }
      &-l {
        padding-block-start: var(--spacing-l);
      }
      &-xl {
        padding-block-start: var(--spacing-xl);
      }
      &-xxl {
        padding-block-start: var(--spacing-xxl);
      }
      &-xxxl {
        padding-block-start: var(--spacing-xxxl);
      }
      &-xxxxl {
        padding-block-start: var(--spacing-xxxxl);
      }
    }
  }
  &--rounded {
    border-radius: var(--radii-m);
  }
  &--border {
    &-primary {
      border: var(--border-m-primary);
    }
    &-primary-light {
      border: var(--border-m-primary-light);
    }
  }
  &--flex {
    display: flex;
    &--reverse {
      flex-direction: row-reverse;
    }
  }
  &--col {
    flex-direction: column;
    &--reverse {
      flex-direction: row-reverse;
    }
  }
  &--gap {
    &-xxxs {
      gap: var(--spacing-xxxs);
    }
    &-xxs {
      gap: var(--spacing-xxs);
    }
    &-xs {
      gap: var(--spacing-xs);
    }
    &-s {
      gap: var(--spacing-s);
    }
    &-m {
      gap: var(--spacing-m);
    }
    &-l {
      gap: var(--spacing-l);
    }
    &-xl {
      gap: var(--spacing-xl);
    }
    &-xxl {
      gap: var(--spacing-xxl);
    }
    &-xxxl {
      gap: var(--spacing-xxxl);
    }
    &-xxxxl {
      gap: var(--spacing-xxxxl);
    }
    &-no {
      gap: 0;
    }
  }
  &--all {
    &-xxxs {
      padding: var(--spacing-xxxs);
    }
    &-xxs {
      padding: var(--spacing-xxs);
    }
    &-xs {
      padding: var(--spacing-xs);
    }
    &-s {
      padding: var(--spacing-s);
    }
    &-m {
      padding: var(--spacing-m);
    }
    &-l {
      padding: var(--spacing-l);
    }
    &-xl {
      padding: var(--spacing-xl);
    }
    &-xxl {
      padding: var(--spacing-xxl);
    }
    &-xxxl {
      padding: var(--spacing-xxxl);
    }
    &-xxxxl {
      padding: var(--spacing-xxxxl);
    }
  }
  &--justifyContent {
    &-center {
      justify-content: center;
    }
    &-start {
      justify-content: start;
    }
    &-end {
      justify-content: end;
    }
    &-space-between {
      justify-content: space-between;
    }
  }
  &--alignItems {
    &-center {
      align-items: center;
    }
    &-start {
      align-items: start;
    }
    &-end {
      align-items: end;
    }
  }
  &--flexRatio {
    &-0 {
      flex: 0 0 auto;
    }
    &-1 {
      flex: 1 1 0px;
    }
    &-2 {
      flex: 2;
    }
  }

  &--hidden {
    visibility: hidden;
  }
}
