@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Roboto&display=swap");
@font-face {
  font-family: "TT Rounds Neue Trial Regular";
  src:
    local("TT Rounds Neue Trial Regular"),
    url("../../fonts/TT Rounds Neue Trial Regular.ttf") format("ttf");
}

@font-face {
  font-family: "TT Rounds Neue Trial Condensed Regular";
  src:
    local("TT Rounds Neue Trial Condensed Regular"),
    url("../../fonts/TT Rounds Neue Trial Condensed Regular.ttf") format("ttf");
}

:root {
  // font
  --font-text: "TT Rounds Neue Trial Regular", sans-serif;
  --font-heading: "TT Rounds Neue Trial Condensed Regular", sans-serif;
  --font-size-xs: 0.75rem;
  --font-size-s: 0.875rem;
  --font-size-m: 1rem;
  --font-size-l: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-xxl: 1.5rem;
  --font-size-xxxl: 2rem;
  --font-size-xxxxl: 2.5rem;
  --font-weight-bold: 700;
  --font-weight-medium: 500;
  --font-weight-regular: 400;

  // Colors
  // Headings
  --color-heading-main: var(--color-text-main);
  --color-heading-primary: var(--color-background-primary);
  // Background
  --color-background-primary: #008c95;
  --color-background-primary-light: #71dbd4;
  --color-background-primary-extra-light: #b8fcf8;
  // Text
  --color-text-main: #253746;
  --color-text-white: white;
  --color-text-primary: var(--color-text-main);
  --color-text-primary-light: var(--color-background-primary-light);
  // Border
  --color-border-primary: #4a8180;
  --color-border-primary-light: var(--color-background-primary-light);

  // Spacing
  --spacing-xxxs: 4px;
  --spacing-xxs: 8px;
  --spacing-xs: 12px;
  --spacing-s: 16px;
  --spacing-m: 20px;
  --spacing-l: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 40px;
  --spacing-xxxl: 48px;
  --spacing-xxxxl: 64px;

  // Border
  --border-width-s: 1px;
  --border-width-m: 2px;
  --border-width-l: 4px;
  --border-m-primary: var(--border-width-m) solid var(--color-border-primary);
  --border-m-primary-light: var(--border-width-m) solid
    var(--color-border-primary-light);
  --border-l-white: var(--border-width-l) solid white;

  // Radii
  --radii-s: 4px;
  --radii-m: 8px;
  --radii-l: 16px;
  --radii-rounded: 50%;

  // Breakpoints
  --breakpoint-mobile: 480px;
  --breakpoint-tablet: 768px;
  --breakpoint-desktop: 1024px;
  --breakpoint-large-desktop: 1200px;
}
